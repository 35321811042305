<template>
	<div class="NotFound">
		<div class="NotFound-box">
			<div class="name">404</div>
			<p class="text">找不到您要查找的页面</p>
			<div>
				<el-button size="mini" @click="$router.go(-1)">返回上一步</el-button>
				<el-button class="el-white" @click="signout" color="#488bff" style="color: white" size="mini">重新登录
				</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			// 重新登录
			signout() {
				sessionStorage.removeItem('token')
				let getroute = []
				this.$store.dispatch('GETNAVLIST', getroute)
				this.$router.push('/login')
			}
		}
	}
</script>

<style scoped lang="scss">
	.NotFound {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		button {
			font-size: 14px;
			padding: 10px 20px;
			margin: 0 10px;
		}

		.name {
			font-size: 120px;
			font-weight: 400;
			color: #6c757d;
			margin-top: -100px;
		}

		.text {
			font-size: 16px;
			font-weight: 400;
			color: #999;
			margin: 30px 0;
		}
	}
</style>
